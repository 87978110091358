var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "main", staticClass: "zoomable-image" },
    [
      _c("canvas", { ref: "canvas" }),
      _vm.isViewerHostLike
        ? [
            _vm.complete
              ? _c(
                  "div",
                  {
                    staticClass: "zoomable-image__play-pause-btn",
                    on: { click: _vm.replay },
                  },
                  [_c("i", { staticClass: "fas fa-play" })]
                )
              : _vm.playing
              ? _c(
                  "div",
                  {
                    staticClass: "zoomable-image__play-pause-btn",
                    on: { click: _vm.pause },
                  },
                  [_c("i", { staticClass: "fas fa-pause" })]
                )
              : _c(
                  "div",
                  {
                    staticClass: "zoomable-image__play-pause-btn",
                    on: { click: _vm.play },
                  },
                  [_c("i", { staticClass: "fas fa-play" })]
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }