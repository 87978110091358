

























import {
  computed,
  defineComponent,
  toRefs,
  ref,
  onMounted,
  onBeforeUnmount
} from "@vue/composition-api"
import { TweenMax, TimelineLite } from "gsap/TweenMax"
import { watchDebounced } from "@vueuse/core"

import useStore from "@/use/useStore"

import useZoomableImageStateRef from "./useZoomableImageStateRef"
import useZoomableImage from "./useZoomableImage"

const INITIAL_SCALE = 5
const INITIAL_BLUR = 50

export default defineComponent({
  name: "ZoomableImage",
  props: {
    src: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      default: 30
    }
  },
  setup(props) {
    const { src, duration } = toRefs(props)
    const { store } = useStore()
    const { ref: stateRef } = useZoomableImageStateRef()

    const tl = new TimelineLite({
      paused: true
    })

    const { pause } = useZoomableImage(tl)

    const obj = { scale: INITIAL_SCALE, blur: INITIAL_BLUR }
    const state = ref<{ playing?: boolean; progress?: number } | undefined>()
    const playing = computed(() => state.value?.playing ?? false)
    const complete = ref(false)
    const isViewerHostLike = computed(
      () => store.getters["group/isViewerHostLike"]
    )

    watchDebounced(
      state,
      value => {
        const progress = value?.progress ?? 0
        const playing = value?.playing ?? false
        tl.time(progress)
        if (playing) tl.play()
        else tl.pause()
      },
      { immediate: true, debounce: 300 }
    )

    function load(src: string): Promise<HTMLImageElement> {
      return new Promise((resolve, reject) => {
        const img = new Image()
        const onLoad = () => {
          img.removeEventListener("load", onLoad)
          img.removeEventListener("error", onError)
          resolve(img)
        }
        const onError = e => {
          img.removeEventListener("load", onLoad)
          img.removeEventListener("error", onError)
          reject(e)
        }
        img.addEventListener("load", onLoad)
        img.addEventListener("error", onError)
        img.src = src
      })
    }

    let ctx: CanvasRenderingContext2D
    let width: number
    let height: number
    let image: HTMLImageElement

    function onTick() {
      if (ctx == null) return
      if (width == null) return
      if (height == null) return
      if (image == null) return

      if (complete.value === false) {
        if (obj.scale === 1) complete.value = true
      }

      if (complete.value === true) {
        if (obj.scale !== 1) complete.value = false
      }

      ctx.clearRect(0, 0, width, height)
      ctx.filter = `blur(${obj.blur}px)`
      ctx.drawImage(image, 0, 0, width * obj.scale, height * obj.scale)
    }

    tl.to(obj, duration.value, { scale: 1, blur: 0 })

    onMounted(async () => {
      image = await load(src.value)

      canvas.value.width = image.width
      canvas.value.height = image.height
      main.value.style.width = image.width + "px"
      main.value.style.height = image.height + "px"

      width = image.width
      height = image.height

      ctx = canvas.value.getContext("2d")

      TweenMax.ticker.addEventListener("tick", onTick)

      onTick()

      stateRef.on("value", onSnapshot)
    })

    function onSnapshot(snapshot) {
      state.value = snapshot.val()
    }

    onBeforeUnmount(() => {
      stateRef.off("value", onSnapshot)
      TweenMax.ticker.removeEventListener("tick", onTick)
    })

    function play() {
      stateRef.update({
        playing: true
      })
    }

    function replay() {
      stateRef.set(null)
      play()
    }

    const canvas = ref()
    const main = ref()

    return {
      main,
      canvas,
      play,
      replay,
      pause,
      state,
      playing,
      complete,
      isViewerHostLike
    }
  }
})
